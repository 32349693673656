<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo" />
  </div>
  <div id="kpi">
    <!-- <div class="" v-if="evalEmpty">
    <h2>Ne postoji ni jedan Performance Evaluation</h2>
  </div> -->

    <!-- <div class=""  v-if="!evalEmpty"> -->

    <!-- <p>Datum izrade konacne procene: {{procenaDate}}</p> -->

    <h2>Rezultati se otvaraju kada izaberete "Datum procene"</h2>

    <div class="form-group">
      <label for="date">Datum Procene:</label>
      <select
        name="date"
        id="collegue"
        v-model="CurrentDate"
        @change="onChangeDate($event)"
      >
        <option v-for="d in date" v-bind:key="d">{{ d }}</option>
      </select>
    </div>
    <div class="kompetence" v-if="kompetenceProc != 0">
      <h3>Kompetence ({{ kompetenceProc }}%)</h3>
      <table id="kompetenceTable">
        <tr>
          <th>Opis:</th>
          <th>Ponder</th>
          <th>Samoprocena</th>
          <th>Konacna Procena</th>
          <th>Rangiranje</th>
        </tr>

        <tr v-for="(komp, i) in kompetence" v-bind:key="i">
          <td>
            {{ komp.name }}
            <button
              class="definicija"
              type="button"
              name="button"
              @Click="swapBoolKompetence(i)"
            >
              Definicija
            </button>
            <p v-if="kompetenceBool[i].bool" style="text-align: left;">
              <span style="white-space: pre-line">
                {{ kompetenceBool[i].comment }}
              </span>
            </p>
          </td>
          <td>{{ komp.ponder }}%</td>
          <td v-if="komp.samoProcena == 1">Ne Zadovoljava Očekivanja</td>
          <td v-if="komp.samoProcena == 2">Potrebno Poboljšanje</td>
          <td v-if="komp.samoProcena == 3">Zadovoljava Očekivanja</td>
          <td v-if="komp.samoProcena == 4">Iznad Očekivanja</td>
          <td v-if="komp.konacnaProcena == 1">Ne Zadovoljava Očekivanja</td>
          <td v-if="komp.konacnaProcena == 2">Potrebno Poboljšanje</td>
          <td v-if="komp.konacnaProcena == 3">Zadovoljava Očekivanja</td>
          <td v-if="komp.konacnaProcena == 4">Iznad Očekivanja</td>
          <td v-if="komp.rangiranje != null">
            {{ komp.rangiranje.toFixed(2) }}
          </td>
          <td v-if="komp.rangiranje == null">0</td>
        </tr>

        <tr>
          <td colspan="4">Ukupno ocena:</td>
          <td>{{ kompetenceOcena.toFixed(2) }}</td>
        </tr>
      </table>
    </div>

    <div class="licni" v-if="licniProc != 0">
      <h3>Licni KPI ({{ licniProc }}%)</h3>
      <table id="licniTable">
        <tr>
          <th>Opis:</th>
          <th>Ponder</th>
          <th>Samoprocena</th>
          <th>Konacna Procena</th>
          <th>Rangiranje</th>
        </tr>

        <tr v-for="(licni, j) in licniKpi" v-bind:key="j">
          <td>
            {{ licni.name }}
            <button
              class="definicija"
              type="button"
              name="button"
              @Click="swapBoolLicni(j)"
            >
              Definicija
            </button>

            <p v-if="licniBool[j].bool" style="text-align: left;">
              <span style="white-space: pre-line">
                {{ licniBool[j].comment }}
              </span>
            </p>
          </td>
          <td>{{ licni.ponder }}%</td>
          <td v-if="licni.samoProcena == 1">Ne Zadovoljava Očekivanja</td>
          <td v-if="licni.samoProcena == 2">Potrebno Poboljšanje</td>
          <td v-if="licni.samoProcena == 3">Zadovoljava Očekivanja</td>
          <td v-if="licni.samoProcena == 4">Iznad Očekivanja</td>
          <td v-if="licni.konacnaProcena == 1">Ne Zadovoljava Očekivanja</td>
          <td v-if="licni.konacnaProcena == 2">Potrebno Poboljšanje</td>
          <td v-if="licni.konacnaProcena == 3">Zadovoljava Očekivanja</td>
          <td v-if="licni.konacnaProcena == 4">Iznad Očekivanja</td>
          <td v-if="licni.rangiranje != null">
            {{ licni.rangiranje.toFixed(2) }}
          </td>
          <td v-if="licni.rangiranje == null">0</td>
        </tr>

        <tr>
          <td colspan="4">Ukupno ocena:</td>
          <td>{{ licniOcena.toFixed(2) }}test</td>
        </tr>
      </table>
    </div>

    <div class="kompanijski" v-if="kompanijskiProc != 0">
      <h3>Kompanijski KPI ({{ kompanijskiProc }}%)</h3>
      <table id="kompanijskiTable">
        <tr>
          <th>Opis:</th>
          <th>Ponder</th>
          <th>Samoprocena</th>
          <th>Konacna Procena</th>
          <th>Rangiranje</th>
        </tr>

        <tr v-for="(kompa, k) in kompanijskiKpi" v-bind:key="k">
          <td>
            {{ kompa.name }}
            <button
              class="definicija"
              type="button"
              name="button"
              @Click="swapBoolKompanijski(k)"
            >
              Definicija
            </button>

            <p v-if="kompanijskiBool[k].bool" style="text-align: left;">
              <span style="white-space: pre-line">
                {{ kompanijskiBool[k].comment }}
              </span>
            </p>
          </td>

          <td>{{ kompa.ponder }}%</td>
          <td v-if="kompa.samoProcena == 1">Ne Zadovoljava Očekivanja</td>
          <td v-if="kompa.samoProcena == 2">Potrebno Poboljšanje</td>
          <td v-if="kompa.samoProcena == 3">Zadovoljava Očekivanja</td>
          <td v-if="kompa.samoProcena == 4">Iznad Očekivanja</td>
          <td v-if="kompa.konacnaProcena == 1">Ne Zadovoljava Očekivanja</td>
          <td v-if="kompa.konacnaProcena == 2">Potrebno Poboljšanje</td>
          <td v-if="kompa.konacnaProcena == 3">Zadovoljava Očekivanja</td>
          <td v-if="kompa.konacnaProcena == 4">Iznad Očekivanja</td>
          <td v-if="kompa.rangiranje != null">
            {{ kompa.rangiranje.toFixed(2) }}
          </td>
          <td v-if="kompa.rangiranje == null">0</td>
        </tr>

        <tr>
          <td colspan="4">Ukupno ocena:</td>
          <td>{{ kompanijskiOcena.toFixed(2) }}</td>
        </tr>
      </table>
    </div>

    <h4>Komentar Samoprocena:</h4>
    <div id="komentarEval">
      <p style="text-align: left;">
        <span style="white-space: pre-line">
          {{ samoProcena }}
        </span>
      </p>
    </div>

    <h4>Komentar Konacna procena:</h4>
    <div id="komentarEval">
      <p style="text-align: left;">
        <span style="white-space: pre-line">
          {{ konacnaProcena }}
        </span>
      </p>
    </div>
    <h2 id="jedan" v-if="ukupnoKpi < 0.749">
      Ukupan učinak: {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
    </h2>
    <h2 id="dva" v-if="ukupnoKpi >= 0.75 && ukupnoKpi < 0.955">
      Ukupan učinak: {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
    </h2>
    <h2 id="tri" v-if="ukupnoKpi >= 0.955 && ukupnoKpi < 1.175">
      Ukupan učinak: {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
    </h2>
    <h2 id="cetiri" v-if="ukupnoKpi >= 1.175">
      Ukupan učinak: {{ ((ukupnoKpi * 10000 + 1) / 10000).toFixed(2) }}
    </h2>

    <!-- </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";
export default {
  computed: mapGetters(["companys", "user"]),

  data() {
    return {
      CurrentDate: "",
      date: [],
      kompetence: [],
      kompanijskiKpi: [],
      licniKpi: [],
      konacnaProcena: "",
      samoProcena: "",
      kompetenceOcena: 0,
      licniOcena: 0,
      kompanijskiOcena: 0,
      ukupnoKpi: 0,
      kompetenceProc: 0,
      licniProc: 0,
      kompanijskiProc: 0,
      kompetenceBool: [],
      kompanijskiBool: [],
      licniBool: [],
    };
  },

  methods: {
    ...mapActions(["getProfile", "getCompanies"]),

    swapBoolKompetence(i) {
      if (this.kompetenceBool[i].bool) {
        this.kompetenceBool[i].bool = false;
      } else {
        this.kompetenceBool[i].bool = true;
      }
    },
    swapBoolKompanijski(i) {
      if (this.kompanijskiBool[i].bool) {
        this.kompanijskiBool[i].bool = false;
      } else {
        this.kompanijskiBool[i].bool = true;
      }
    },
    swapBoolLicni(i) {
      if (this.licniBool[i].bool) {
        this.licniBool[i].bool = false;
      } else {
        this.licniBool[i].bool = true;
      }
    },
    onChangeDate() {
      this.kompetence = [];
      this.kompanijskiKpi = [];
      this.licniKpi = [];

      for (var i = 0; i < this.user.performanceEvaluation.length; i++) {
        if (event.target.value == this.user.performanceEvaluation[i].date) {
          this.lastEval = i;
        }
      }
      this.konacnaProcena = this.user.performanceEvaluation[
        this.lastEval
      ].komentar;
      this.samoProcena = this.user.performanceEvaluation[
        this.lastEval
      ].komentarSamoProcena;

      this.companyKpiConnector = this.user.performanceEvaluation[
        this.lastEval
      ].companyKpiConnector;

      let tempCompany = {};
      for (i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == this.user.type) {
          tempCompany = this.companys[i];
        }
      }

      //kompetence
      let ukupnoKompetence = 0;
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompetence.length;
        i++
      ) {
        if (
          this.user.performanceEvaluation[this.lastEval].kompetence[i]
            .konacnaProcena != null
        ) {
          ukupnoKompetence += parseFloat(
            this.user.performanceEvaluation[this.lastEval].kompetence[
              i
            ].rangiranje.toFixed(2)
          );
        }
      }
      this.kompetenceOcena = ukupnoKompetence;

      //licni
      let ukupnoLicni = 0;
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].licniKpi.length;
        i++
      ) {
        if (
          this.user.performanceEvaluation[this.lastEval].licniKpi[i]
            .konacnaProcena != null
        ) {
          ukupnoLicni += parseFloat(
            this.user.performanceEvaluation[this.lastEval].licniKpi[
              i
            ].rangiranje.toFixed(2)
          );
        }
      }
      this.licniOcena = ukupnoLicni;

      let ukupnoKompanijski = 0;
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompanijski.length;
        i++
      ) {
        if (
          this.user.performanceEvaluation[this.lastEval].kompanijski[i]
            .konacnaProcena != null
        ) {
          ukupnoKompanijski += parseFloat(
            this.user.performanceEvaluation[this.lastEval].kompanijski[
              i
            ].rangiranje.toFixed(2)
          );
        }
      }
      this.kompanijskiOcena = ukupnoKompanijski;

      this.ukupnoKpi =
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .kompetenceProcenat /
          100) *
          ukupnoKompetence +
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .licniProcenat /
          100) *
          ukupnoLicni +
        (tempCompany.performanceEvaluation[this.companyKpiConnector]
          .kompanijskiProcenat /
          100) *
          ukupnoKompanijski;

      this.kompetenceProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].kompetenceProcenat;
      this.licniProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].licniProcenat;
      this.kompanijskiProc =
        tempCompany.performanceEvaluation[
          this.companyKpiConnector
        ].kompanijskiProcenat;

      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompetence.length;
        i++
      ) {
        let emptyObj = {};
        this.kompetence.push(emptyObj);
        this.kompetence[i].samoProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].samoProcena;
        this.kompetence[i].konacnaProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].konacnaProcena;
        this.kompetence[i].rangiranje = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].rangiranje;
        this.kompetence[i].name = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].name;
        this.kompetence[i].ponder = this.user.performanceEvaluation[
          this.lastEval
        ].kompetence[i].ponder;
      }
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].kompanijski.length;
        i++
      ) {
        let emptyObj = {};
        this.kompanijskiKpi.push(emptyObj);
        this.kompanijskiKpi[i].samoProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].samoProcena;
        this.kompanijskiKpi[i].konacnaProcena = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].konacnaProcena;
        this.kompanijskiKpi[i].rangiranje = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].rangiranje;
        this.kompanijskiKpi[i].name = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].name;
        this.kompanijskiKpi[i].ponder = this.user.performanceEvaluation[
          this.lastEval
        ].kompanijski[i].ponder;
      }
      this.licniKpi = [];
      for (
        i = 0;
        i < this.user.performanceEvaluation[this.lastEval].licniKpi.length;
        i++
      ) {
        this.licniKpi.push(
          this.user.performanceEvaluation[this.lastEval].licniKpi[i]
        );
      }
      //this.updateTable();

      this.kompetenceBool = [];
      this.kompanijskiBool = [];
      this.licniBool = [];

      let brojac = this.user.performanceEvaluation[this.lastEval].kompetence
        .length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment:
            tempCompany.performanceEvaluation[this.companyKpiConnector]
              .kompetence[i].comment,
        };
        this.kompetenceBool.push(komp);
      }
      brojac = this.user.performanceEvaluation[this.lastEval].kompanijski
        .length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment:
            tempCompany.performanceEvaluation[this.companyKpiConnector]
              .kompanijski[i].comment,
        };
        this.kompanijskiBool.push(komp);
      }
      brojac = this.user.performanceEvaluation[this.lastEval].licniKpi.length;
      for (i = 0; i < brojac; i++) {
        let komp = {
          bool: false,
          comment: this.user.performanceEvaluation[this.lastEval].licniKpi[i]
            .comment,
        };
        this.licniBool.push(komp);
      }
    },
  },

  async created() {
    await this.getCompanies();
    await this.getProfile();
    for (var i = 0; i < this.user.performanceEvaluation.length; i++) {
      this.date.push(this.user.performanceEvaluation[i].date);
    }
  },

  beforeCreate: function() {
    document.body.className = "other";
  },
};
</script>

<style lang="css" scoped>
#kpi {
  background-color: #77bbff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin: 15px;
  padding: 15px;

  text-align: center;
  margin: auto;
}
#licniTable {
  margin: auto;
}
#kompanijskiTable {
  margin: auto;
}
#kompetenceTable {
  margin: auto;
}
table {
  border: 2px solid black;
  text-align: center;
}
td,
th {
  text-align: center;
  border: 1px solid black;
}
tr:nth-child(even) {
  background-color: #aaaacc;
}
tr:nth-child(odd) {
  background-color: #ccccff;
}
tr:hover {
  background-color: #afa;
}
#komentarEval {
  background-color: white;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 5px;
}
#jedan {
  background-color: red;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
#dva {
  background-color: yellow;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
#tri {
  background-color: cyan;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
#cetiri {
  background-color: green;
  color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.definicija {
  background-color: #ffff88;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  float: right;
}
.definicija:hover {
  background-color: #dddd66;
}
</style>
